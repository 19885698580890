
const routes = [
  {
    path: '/game',
    component: () => import('layouts/GameLayout.vue'),
    children: [
      { path: '', name: 'game-page', component: () => import('pages/GamePage.vue') },
    ]
  },
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', name: 'index-page', component: () => import('pages/IndexPage.vue') },
    ]
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/ErrorNotFound.vue')
  }
]

export default routes
