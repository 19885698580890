import {api} from 'boot/axios';

export default async () => {

  async function login() {
    return await api.post('auth', {
      token: localStorage.getItem('auth_token'),
    }).then((res) => {
      localStorage.setItem('auth_token', res.data.token);
      localStorage.setItem('user_id', res.data.id);
    })
  }

  await login();
};
